body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 1024px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html,
body {
  height: 100%;
}
#root {
  height: 100%;
}

.app-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#root .fluid-max {
  max-width: 1800px;
  padding: 0;
}

/*
 * Sidebar
 */

.sidebar {
  /*box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);*/
  /*background-color: #f8f9fa !important;*/
  font-size: 0.875rem;
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}

.sidebar .nav,
.sidebar .nav-item {
  display: block;
}

.sidebar .nav-link {
  font-weight: 500;
  color: #333;
  padding: 0.5rem 0;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #999;
}

.sidebar .nav-link span {
  vertical-align: middle;
}

.sidebar .nav-link.active {
  color: #007bff;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar-heading {
  font-size: 0.75rem;
  text-transform: uppercase;
}

/*
 * Content
 */

[role='main'] {
  padding-top: 48px; /* Space for fixed navbar */
}

.v-flex {
  display: flex;
  align-items: center;
}

.mar-r-10 {
  margin-right: 10px;
}

.m-bot-50 {
  margin-bottom: 50px;
}

.m-top-25 {
  margin-top: 25px;
}

.ta-right {
  text-align: right;
}

.like-link {
  cursor: pointer;
}

.like-link:hover {
  color: #585858;
}
